@import url("https://fonts.googleapis.com/css2?family=ABeeZee:ital@0;1&family=Aclonica&family=Alata&family=Aoboshi+One&family=Inter:wght@100..900&family=Nunito:ital,wght@0,200..1000;1,200..1000&family=Oldenburg&family=Palanquin:wght@100;200;300;400;500;600;700&family=Paytone+One&family=Playball&family=Playfair+Display:ital,wght@0,400..900;1,400..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Advent+Pro:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Carrois+Gothic&display=swap");
@import url("https://db.onlinewebfonts.com/c/0a6ee448d1bd65c56f6cf256a7c6f20a?family=Bahnschrift");
@import url("https://fonts.googleapis.com/css2?family=Sacramento&display=swap");
@font-face {
  font-family: "TT1020M";
  src: url("fonts/TT1020M_.TTF") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Breathing";
  src: url("fonts/Breathing.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Copperplate Gothic Bold";
  src: url("fonts/COPRGTB.TTF") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Rockwell";
  src: url("fonts/ROCC____.TTF") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "OstrichSans-Regular";
  src: url("fonts/ostrich-regular.ttf") format("truetype"); /* Adjusted path and format */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "OstrichSans-Black";
  src: url("fonts/OstrichSans-Black.otf") format("opentype"); /* Adjusted path and format */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Beyond Infinity";
  src: url("fonts/Beyond\ Infinity\ -\ Demo.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Bahn";
  src: url("fonts/bahnschrift.ttf") format("truetype");
  font-weight: lighter;
  font-style: normal;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.navbar {
  box-shadow: 0px 4px 45px 0px #00000040;
}

.nav-item a {
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  font-weight: 500;
  color: #000 !important;
  margin: 0 15px;
}

.active {
  font-weight: 600 !important;
}

.nav-item button {
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  font-weight: 500;
  color: #000 !important;
  margin: 0 15px;
}

.nav-item button:hover {
  font-weight: 600;
}

.nav-item a:hover {
  font-weight: 600;
}

@media (max-width: 500px) {
  .nav-fluid {
    padding: 0 20px !important;
  }
  .navbar-nav {
    margin-left: 0;
  }
}

.first-btn {
  border: 1px solid #38a3a5;
  background-color: #38a3a5;
  color: white;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 500;
  padding: 10px 45px;
  border-radius: 25px;
}

.first-btn:hover {
  background-color: white;
  color: #38a3a5;
  transition: all 0.3s ease;
}

.nav-buttons img {
  width: 50px;
  height: 50px;
  margin-right: 10px;
}
.nav-buttons {
  display: flex;
  align-items: center;
}

.nav-buttons i {
  margin-left: 10px;
}

.nav-buttons h6 {
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  font-weight: 500;
}

.nav-item {
  position: relative;
}

.dropdown-menu {
  display: none;
  position: absolute;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  top: calc(100% + -3px);
  left: 15%;
  background-color: #fff;
  border-top: none;
  border-radius: 5px !important;
  z-index: 1;
  opacity: 0;
  transform: translateY(-10px);
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.dropdown-menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.dropdown-menu ul li {
  padding: 10px;
  /* border-bottom: 1px solid #ccc; */
}

.dropdown-menu ul li:last-child {
  border-bottom: none;
}

.dropdown-menu ul li a {
  text-decoration: none;
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  font-weight: 600;
  color: #212529;
  display: block;
}

.nav-item:hover .dropdown-menu {
  display: block;
  opacity: 1; /* Show with smooth transition */
  transform: translateY(1px); /* Adjust dropdown position */
  border-radius: 20px;
  border: none;
}

.nav-item:hover .dropdown-menu ul li:hover {
  background-color: #f0f0f0; /* Background color on hover */
}

.footer {
  padding-top: 4%;
  background: #38a3a5;
}

.footer-content p {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 27px;
  color: #fff;
  padding-top: 3%;
  padding-bottom: 3%;
}

.footer-content h6,
.footer-list h6 {
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  font-weight: 400;
  color: #fff;
  padding-top: 3%;
  padding-bottom: 3%;
}

.footer-imgs img {
  padding-right: 5%;
}

.footer-list h3 {
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  font-weight: 500;
  color: #fff;
  margin-top: 3%;
  padding-bottom: 5%;
}

.footer-list li {
  list-style: none;
  padding-bottom: 3%;
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  font-weight: 400;
  color: #fff;
}

.footer-list li:hover {
  color: #01003e;
  cursor: pointer;
}

.footer-row2 {
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 19.69px;
  text-align: center;
  color: #fff;
  margin-top: 3% !important;
  padding-top: 3%;
  border-top: 1px solid #ffffff;
}

.footer-social {
  margin-top: 6%;
}

.footer-social i {
  padding: 5px 8px;
  background: #fff;
  border-radius: 50%;
  margin-right: 4%;
  cursor: pointer;
}

.bi-facebook:hover {
  color: #45c4e9;
}
.bi-twitter:hover {
  color: #03bff8;
}
.bi-instagram:hover {
  color: #ff6363;
}

.main {
  background-image: url("../public/imgs-calendar/GiftOnly4UBanner.gif");
  background-size: cover;
  padding: 3%;
  margin-bottom: 4%;
  border-radius: 0px 0px 100px 100px;

  /* min-height: 50vh !important; */
}

.main-content h1 {
  font-family: "Paytone One", sans-serif;
  font-size: 48px;
  font-weight: 400;
  line-height: 67.01px;
  text-align: left;
  color: #fff;
}

.main-content p {
  font-family: "Palanquin", sans-serif;
  font-size: 24px;
  font-weight: 600;
  line-height: 43.46px;
  text-align: left;
  color: #fff;
  padding-top: 3%;
  padding-bottom: 2%;
}

.main-text p {
  font-family: "Bahnschrift", sans-serif;
  /* font-size: 9.5pt; */
  font-size: 18px;
  font-weight: 600;
  line-height: 23.46px;
  text-align: left;
  color: #fff;
  margin-left: 18px;
}
.main-button button {
  padding: 10px 50px;
  background: linear-gradient(90deg, #fcc067 0%, #efff3c 100%);
  color: #01003e;
  border: 0;
  font-family: "Palanquin", sans-serif;
  font-size: 20px;
  font-weight: 600;
  border-radius: 25px;
  margin-top: 4%;
}

.steps {
  padding-bottom: 4%;
}

.steps-header {
  padding-bottom: 4%;
}

.steps-header h1 {
  font-family: "Alata", sans-serif;
  font-size: 40px;
  font-weight: 400;
  line-height: 80px;
  text-align: center;
  color: #01003e;
}

.steps-header p {
  font-family: "Alata", sans-serif;
  font-size: 22px;
  font-weight: 400;
  line-height: 44px;
  text-align: center;
  color: #747272;
}

.steps-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 4px 30px 0px #00000040;
  border-radius: 10px;
  padding-top: 5%;
  padding-left: 3%;
  padding-right: 3%;
  margin-bottom: 15%;
}

.steps-card span {
  padding: 8px 22px;
  border-radius: 50%;
  color: #fff;
  background: radial-gradient(
    525.96% 96.75% at 57.14% 50.65%,
    #38a3a5 0%,
    #45c4e9 38.2%,
    #c6f2ff 100%
  );
  font-family: "Inter", sans-serif;
  font-size: 27px;
  font-weight: 600;
}

.steps-card h2 {
  font-family: "Alata", sans-serif;
  font-size: 20px;
  font-weight: 400;
  padding-top: 3%;
  padding-bottom: 1%;
  color: #01003e;
}

.steps-card p {
  font-family: "Inter", sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 30px;
  text-align: center;
}

.steps-row2 {
  display: flex;
  justify-content: space-evenly;
}

.design {
  background: #d9e3ff;
  padding-top: 2%;
}

.design-img img {
  width: 100%;
}

.main-center {
  display: flex;
  justify-content: center;
}

.main-center button {
  margin-top: 0;
}

.event {
  padding-top: 2%;
}

.service {
  background: #88020221;
  padding-top: 2%;
  padding-bottom: 2%;
}

.sc {
  padding: 2%;
}

.service-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4%;
  border: 1px solid #747272;
  border-radius: 10px;
}

.service-card h2 {
  font-family: "Alata", sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 44px;
  text-align: center;
  color: #1e1e1e;
}

.service-card p {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  color: #565656;
}

.faq {
  padding-top: 2%;
  padding-bottom: 2%;
}

.f-card {
  padding: 2%;
  border-radius: 3.48px;
  margin-bottom: 2%;
  border-bottom: 1px solid #eaeaea;
}

.f-card h3 {
  font-family: "Inter", sans-serif;
  font-size: 15.9px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  color: #000;
}

.f-card i {
  color: black;
  cursor: pointer;
}

.f-card p {
  font-family: "Inter", sans-serif;
  font-size: 15.6px;
  font-weight: 400;
  line-height: 25px;
  text-align: left;
  color: #171717;
}

.login {
  background: #edfef8;
  /* display: flex; */
}

.login-row {
  min-height: 100vh;
}

.login-right {
  box-shadow: 0px 4px 45px 0px #00000040;
  background: #ffffff;
  border-radius: 100px 0px 0px 100px;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  padding: 10%;
}

.register-right {
  padding: 3% 10%;
}

.login-right form {
  width: 100%;
}

.img-login {
  display: flex;
  justify-content: center;
  padding-top: 10%;

  position: fixed;
}

.img-login img {
  width: 80%;
}

.login-item {
  margin-bottom: 3%;
}

.login-item label {
  display: block;
  font-family: "Alata", sans-serif;
  font-size: 18px;
  font-weight: 400;
  text-align: left;
  color: #1e1e1e;
  margin-bottom: 2%;
}

.submit label {
  font-family: "Alata", sans-serif;
  font-size: 18px;
  font-weight: 400;
  text-align: left;
  color: #1e1e1e;
}

.login-item input {
  width: 100%;
  border: 1px solid #747272;
  border-radius: 10px;
  font-family: "Alata", sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: #747272;
  padding: 2%;
}

.login-item input:focus {
  outline: none;
}

.login-right h1 {
  font-family: "Aclonica", sans-serif;
  font-size: 40px;
  font-weight: 400;
  text-align: center;
  color: #38a3a5;
}

.login-right p {
  font-family: "ABeeZee", sans-serif;
  font-size: 20px;
  font-style: italic;
  font-weight: 400;
  line-height: 23.64px;
  text-align: center;
  color: #747272;
}

.forgot p {
  font-family: "Alata", sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: #38a3a5;
  font-style: normal;
}

.otp-timer-text,
.otp-timer-button {
  font-family: "Alata", sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: #38a3a5;
  font-style: normal;
}

.login-button button {
  width: 100%;
  padding: 10px;
  background-color: #38a3a5;
  border: 2px solid #38a3a5;
  color: #fff;
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  border-radius: 10px;
  margin-top: 4%;
}

.login-button button:hover {
  background-color: transparent;
  color: #38a3a5;
  transition: all 0.3s ease-in-out;
}

.account {
  font-family: "Alata", sans-serif;
  font-style: normal !important;
  font-size: 16px !important;
  font-weight: 400;
  line-height: 24.84px;
  text-align: center;
  color: #000 !important;
  margin-top: 2%;
}

.account span {
  color: #38a3a5;
}

.login-append span {
  background: transparent;
  border: 0;
  border-radius: 10px;
  margin-top: 3px;
  cursor: pointer;
}

.login-group {
  width: 100%;
  /* border: 1px solid #747272;
  border-radius: 10px; */
}

.login-group input {
  border-right: 0 !important;
  border: 1px solid #747272;
  border-radius: 10px;
}

.login-group input:focus {
  box-shadow: none;
  outline: none;
  border: 1px solid #747272;
}

.login-append span {
  border-left: 0 !important;
  border: 1px solid #747272;
  border-radius: 0px 10px 10px 0px;
  height: 100%;
  margin-top: 0;
}

.otp-input-fields {
  margin: auto;
  background-color: white;
  /* box-shadow: 0px 0px 8px 0px #02025044; */
  /* max-width: 400px; */
  width: auto;
  display: flex;
  justify-content: center;
  gap: 10px;
  padding: 20px;
}

.otp-input-fields input {
  height: 40px;
  width: 40px;
  background-color: transparent;
  border-radius: 4px;
  border: 1px solid #747272;
  text-align: center;
  outline: none;
  font-family: "Alata", sans-serif;
  font-size: 16px;
  font-weight: 400;
}

.otp-input-fields input::-webkit-outer-spin-button,
.otp-input-fields input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.otp-input-fields input[type="number"] {
  -moz-appearance: textfield;
}

.otp-input-fields input:focus {
  border-width: 2px;
  border-color: #747272;
  font-size: 20px;
}

.result {
  max-width: 400px;
  margin: auto;
  padding: 24px;
  text-align: center;
}

.result p {
  font-size: 24px;
  font-family: "AAlata", sans-serif;
  opacity: 1;
  transition: color 0.5s ease;
}

.result p._ok {
  color: #747272;
}

.result p._notok {
  color: red;
  border-radius: 3px;
}

.privacy {
  padding-top: 1%;
  padding-bottom: 4%;
}

.privacy h1 {
  font-family: "Montserrat", sans-serif;
  font-size: 25px;
  font-weight: 700;
  line-height: 31px;
  padding-top: 3%;
  padding-bottom: 3%;
}

.privacy p {
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-weight: 300;
  line-height: 31px;
  text-align: justify;
}

.privacy h5 {
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 31px;
}

.image-header {
  padding-top: 3%;
  padding-bottom: 3%;
}

.image-header h1 {
  font-family: "Alata", sans-serif;
  font-size: 30px;
  font-weight: 500;
  text-align: center;
  color: #01003e;
}

.progress-container {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 5%;
}

.progress-line {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 2px;
  background: transparent;
  border-top: 2px dotted #ccc;
  z-index: 1;
}

.circle {
  position: relative;
  width: 50px;
  height: 50px;
  background-color: #fff;
  border: 2px solid #01003e;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  font-family: "Alata", sans-serif;
  font-size: 20px;
  text-align: center;
  color: #01003e;
}

.circle a {
  text-decoration: none;
  color: #01003e;
}
.circle.active a {
  text-decoration: none;
  color: #fff;
}

.circle.active {
  background: radial-gradient(50% 50% at 50% 50%, #0194fe 0%, #015998 100%);
  color: #fff;
}

.image-content h3 {
  font-family: "Aoboshi One", sans-serif;
  font-size: 22px;
  font-weight: 400;
  color: #015998;
}

.coupon-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
  width: 100%;
  margin-bottom: 2%;
}

.image-content .note {
  font-family: "Inter";
  font-size: 13px;
  color: #545454;
  font-style: italic;
  margin-bottom: 0;
}

.upload {
  padding: 3%;
  padding-left: 10%;
  padding-right: 10%;
  background: #efefef;
  border-radius: 10px;
}

.img-input {
  margin-bottom: 15px;
}

.img-input h6 {
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
  text-align: center;
  padding: 4%;
}

.upload-button {
  display: flex;
  justify-content: center;
  padding: 3%;
}

.upload-button button {
  background: #38a3a5;
  padding: 2px 10px;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  color: #fff;
  border: 1px solid #38a3a5;
  border-radius: 6px;
}

.upload-button button:hover {
  background: transparent;
  color: #38a3a5;
  transition: all 0.3s ease-in-out;
}

.image-button {
  display: flex;
  justify-content: center;
  margin-top: 5%;
  margin-bottom: 3%;
}

.loader-c {
  display: flex;
  justify-content: center;
  margin-bottom: 5%;
  margin-top: 3%;
}

/* HTML: <div class="loader"></div> */
.loader {
  border: 3px solid rgba(255, 255, 255, 0.1);
  border-radius: 50%;
  border-top: 3px solid #ffffff;
  width: 24px;
  height: 24px;
  animation: spin 0.8s linear infinite;
  display: inline-block;
  vertical-align: middle;
}
@keyframes l3 {
  to {
    transform: rotate(1turn);
  }
}

.image-button button {
  padding: 10px 45px;
  background: #38a3a5;
  border-radius: 10px;
  border: 2px solid #38a3a5;
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  font-weight: 500;
  color: #fff;
}

.image-button button:hover {
  background: transparent;
  color: #38a3a5;
  transition: all 0.3s ease-in-out;
}

.tab button {
  display: block;
  padding: 6px;
  background: transparent;
  width: 100%;

  font-size: 18px;
  font-family: "Poppins", sans-serif;
  color: #015998;
  font-weight: 500;
  border-radius: 10px;
  margin-bottom: 3%;
  border: 2px solid #38a3a5;
}

.tab button:hover {
  background: #38a3a5;
  color: white;
  transition: all 0.3s ease-in-out;
}

.tab button.active {
  background-color: #38a3a5;
  color: white;
}

.holiday,
.event-card {
  border: 1px solid #38a3a5;
  padding: 3%;
  margin-bottom: 2%;
}

.holiday h2 {
  font-family: "Poppins", sans-serif;
  font-size: 22px;
  font-weight: 500;
  color: #01003e;
}

.holiday-1 li {
  list-style: none;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
  color: #747272;
}

.holiday-1 span {
  padding: 0px 10px;
  margin-right: 15px;
}

.holiday-1 .c1 {
  background: #9ed6fe;
}
.holiday-1 .c2 {
  background: #a0fe9e;
}
.holiday-1 .c3 {
  background: #fe9ee9;
}

.events {
  padding-top: 3%;
  padding-bottom: 3%;
  display: none;
}

.events.active {
  display: block;
}

.events h4,
.event-card h2 {
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  font-weight: 500;
  color: #01003e;
}

.event-item {
  margin-bottom: 3%;
}

.event-item label {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 40px;
  padding-right: 5%;
}

.event-item input {
  width: 100%;
  padding: 1%;
  border: 1px solid #747272;
  font-family: "Poppins";
  font-size: 16px;
  font-weight: 400;
  color: #747272;
  border-radius: 6px;
}
.event-item input[type="color"] {
  height: 33px;
}

.event-buttons {
  display: flex;
  justify-content: end;
}

.b1 {
  border: 2px solid #d00000;
  color: #d00000;
  background: transparent;
  padding: 4px 10px;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  border-radius: 10px;
}

.b1:hover {
  background: #d00000;
  color: #fff;
  transition: all 0.3s ease-in-out;
}
.b2 {
  border: 2px solid #38a3a5;
  color: #fff;
  background: #38a3a5;
  padding: 4px 30px;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  border-radius: 10px;
  margin-left: 3%;
}

.b2:hover {
  background: #fff;
  color: #38a3a5;
  transition: all 0.3s ease-in-out;
}

.preview {
  max-height: 100vh;
}

.top-buttons {
  /* padding-top: 2%;
  padding-bottom: 2%; */
  display: flex;
  justify-content: space-between;
}

.top-buttons button {
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 500;
  background: transparent;
  border: 0;
  color: #000;
}

.preview-center {
  height: 60vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "OstrichSans-Regular", sans-serif;
  font-size: 52px;
  font-weight: 500;
  line-height: 72px;
  text-align: center;
  color: #000;
}

.preview-center-coupon {
  height: 60vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "OstrichSans-Regular", sans-serif;

  font-weight: 500;
  line-height: 72px;
  text-align: center;
  color: #000;
  margin-top: 40px;
}

.preview-center-coupon h1 {
  font-size: 56px;
}

.preview-center h1 {
  font-size: 40px;
}
.preview2-center {
  height: 70vh;
}

.preview2-center h2 {
  font-family: "Carrois Gothic", sans-serif;
  font-size: 48px;
  font-weight: 400;
  line-height: 48px;
  padding-top: 2%;
  padding-bottom: 2%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.preview2-center p {
  font-family: "Carrois Gothic", sans-serif;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  font-family: "Carrois Gothic", sans-serif;
  color: #565656;
  text-align: start;
  padding-top: 24px;
}

.preview-center span {
  font-family: "OstrichSans-Regular", sans-serif;
  font-weight: 600;
  color: #ff3868;
}

.courier {
  text-align: center;
}

.preview-footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.preview-footer h3 {
  font-family: "OstrichSans-Black", sans-serif;
  font-size: 28px;
  font-weight: 400;
  line-height: 38px;
  text-align: center;
  padding-bottom: 0px;
  margin-bottom: 0;
}

.preview-footer h6 {
  font-family: "Bahnschrift";
  font-size: 18px;
  font-weight: 500;
}

.address p {
  font-family: "Carrois Gothic", sans-serif;
  font-size: 22px;
  font-weight: 400;
  line-height: 24px;
  color: #636060;
  text-align: right;
  padding: 0 100px;
}
.address-1 {
  padding: 0 120px;
}

.prev-img img {
  width: 100%;
}

.prev-img-last img {
  width: 100%;
  height: 75vh;
}

.preview-agree label {
  font-family: "Inter", sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
  margin-left: 2%;
  cursor: pointer;
}

.a1 {
  padding: 5px 30px;
  background: transparent;
  border: 1px solid #38a3a5;
  border-radius: 10px;
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  font-weight: 500;
  color: #38a3a5;
}

.a1:hover {
  background: #38a3a5;
  color: #fff;
  transition: all 0.3s ease-in-out;
}

.a2 {
  padding: 5px 30px;
  background: #38a3a5;
  border: 1px solid #38a3a5;
  border-radius: 10px;
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  font-weight: 500;
  color: #fff;
}
.a2:hover {
  background: transparent;
  color: #38a3a5;
  transition: all 0.3s ease-in-out;
}

.bill-row h4 {
  font-family: "Aoboshi One";
  font-size: 18px;
  margin: 20px 0;
}

.bill-item {
  margin-bottom: 3%;
  margin-top: 1%;
  /* width: 20rem; */
}
.coupon-field {
  width: 20rem;
}

.bill-item label {
  display: block;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 1.5%;
}

.bill-item input,
.bill-item select,
.bill-item textarea {
  width: 100%;
  padding: 2%;
  padding-left: 2%;
  border-radius: 10px;
  border: 1px solid #747272;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: #000 !important;
}

.summary-card {
  padding: 4%;
  background: #01599812;
}

.summary-card h4 {
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  text-align: left;
  margin-bottom: 4%;
}

.bottom-line {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #a1a1a1;
  margin-bottom: 2%;
  padding-bottom: 5px;
}

.bottom-line span {
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 21.78px;
}

.summary-card img {
  cursor: pointer;
}

.congrats {
  background: #01599812;
  box-shadow: 0px 4px 25px 0px #00000040;
  border-radius: 10px;
  padding: 4%;
  margin-top: 4%;
  margin-bottom: 4%;
}
.profile {
  margin-top: 0%;
}

.profile h5 {
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
  text-align: left;
  padding-top: 15px;
}

.profile-header p {
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  font-weight: 500;
  line-height: 18px;
  text-align: left;
  color: #636060;
}

.profile-header span {
  font-family: "Poppins";
  font-size: 15px;
  font-weight: 400;
  line-height: 16px;
  color: #747272;
  padding: 2px 14px;
  border: 1px solid #636060;
  border-radius: 25px;
}

.profile-icon {
  display: flex;
  align-items: center !important;
  justify-content: center;
}

.profile-item {
  margin-top: 2%;
}

.profile-item p:first-child {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 18px;
  text-align: left;
  color: #747272;
}

.profile-item p:last-child {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 18px;
  text-align: left;
  color: #000;
}

.congrats h1 {
  font-family: "Aclonica", sans-serif;
  font-size: 40px;
  font-weight: 400;
  line-height: 54.4px;
  text-align: center;
  color: #01003e;
  margin-top: 2%;
  margin-bottom: 2%;
}

.congrats h6 {
  font-family: "Aoboshi One";
  font-size: 20px;
  font-weight: 400;
  line-height: 34.75px;
  text-align: center;
  color: #636060;
}

.congrats h4 {
  font-family: "Aoboshi One";
  font-size: 22px;
  font-weight: 400;
  line-height: 37.65px;
  text-align: center;
  color: #015998;
  margin-top: 3%;
  margin-bottom: 3%;
}

.congrats-button {
  display: flex;
  justify-content: center;
}

.previewC-button {
  display: flex;
  justify-content: space-between;
}
.orderT-button {
  display: flex;
  justify-content: end;
}

.congrats-button button {
  padding: 8px 35px;
  border-radius: 25px;
  background: #38a3a5;
  border: 1px solid #38a3a5;
  color: #fff;
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 33px;
  text-align: center;
}

.congrats-button button:hover {
  background: transparent;
  color: #38a3a5;
  transition: all 0.3s ease-in-out;
}

.tab-order {
  display: flex;
  justify-content: center;
  margin-top: 3%;
  margin-bottom: 3%;
  /* padding: 1%; */
  border-bottom: 1px solid #c8c8c8;
}

.tab-order button {
  background: transparent;
  border: 0;
  color: #000;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 500;
  padding-left: 8%;
  padding-right: 8%;
  padding-bottom: 1%;
}

.tab-order button:hover {
  color: #38a3a5;
  border-bottom: 3px solid #38a3a5;
}
.tab-order button.active {
  color: #38a3a5;
  border-bottom: 3px solid #38a3a5;
}

.tab-order-content {
  display: none;
}

.tab-order-content.active {
  display: block;
}

.order-card {
  border: 1px solid #f0f0f0;
  background: #ffffff;
  box-shadow: 0px 4px 25px 0px #d4d4d4;
  padding: 2% !important;
  border-radius: 5px;
  margin-bottom: 2%;
}

.order-c {
  display: flex;
  justify-content: space-between;
}

.order-c span {
  font-family: "Alata", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 24.48px;
  color: #000;
}

.order-span {
  font-family: "Alata";
  font-size: 15px;
  font-weight: 400;
  margin-bottom: 0;
  padding: 10px 0;
}

.order-span span {
  margin-left: 10px;
  padding: 2px 8px;
  background: #389eea47;
  border-radius: 20px;
  font-family: "Inter";
  font-size: 15px;
  font-weight: 400;
}

.rbc-header {
  color: #1a1818;
  background-color: #afcb06;
  text-transform: uppercase;
  font-weight: bold;
  font-family: "Inter", sans-serif;
  font-size: 16px !important;
  padding: 5px !important;
  text-align: center;
  border-right: none !important;
}

.rbc-header + .rbc-header {
  border-left: none !important;
}

.rbc-date-cell button {
  font-size: 14px !important;
  font-family: "Inter", sans-serif;
  font-weight: 500 !important;
  margin-top: 5px;
  margin-bottom: 5px;
}

.rbc-day-bg {
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.rbc-date-cell {
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.rbc-header:nth-child(7n + 7),
.weekend-date.saturday {
  color: #ef7c00 !important;
}

.rbc-header:nth-child(7n + 1),
.weekend-date.sunday {
  color: #e3000e !important;
}

.react-calendar {
  width: 100% !important;
  object-fit: contain;
  height: 100%;
  /* height: 100% !important; */
}

.react-calendar__month-view__days {
  height: 30vh;
}

.saturday {
  color: #ef7c00 !important;
}

/* Set styles for Sundays */
.sunday {
  color: #e3000e !important;
}

/* Calendar tile styles */
.react-calendar__tile {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-family: "Inter", sans-serif;
}

.react-calendar__navigation {
  display: none !important; /* Hides the navigation */
}

.react-calendar__month-view__weekdays {
  color: #1a1818;
  background-color: #afcb06;
  text-transform: uppercase;
  font-weight: bold;
  font-family: "Inter", sans-serif;
  font-size: 16px !important;
  padding: 5px !important;
  text-align: center;
  border-right: none !important;
}

.react-calendar__month-view__weekdays__weekday--weekend:nth-child(6) abbr {
  color: #ef7c00 !important; /* Saturday color */
}

/* Style for Sunday */
.react-calendar__month-view__weekdays__weekday--weekend:nth-child(7) abbr {
  color: #e3000e !important; /* Sunday color */
}

.saturday {
  background-color: #ffbd769c !important;
}
.sunday {
  background-color: #e3000f44 !important;
}

.date-with-event {
  background-color: #01599871;
  color: #01003e !important;
}

/* Date cell styles */
.react-calendar__month-view__days__day {
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px !important;
  font-family: "Inter", sans-serif;
  font-weight: 500 !important;
  margin-top: 5px;
  margin-bottom: 5px;
}
.react-calendar__month-view__days__day {
  border: 0.75px solid #e7e7e7 !important; /* Add border for grid effect */
  box-sizing: border-box; /* Ensure padding and border are included in the element's total width and height */
}

abbr[title] {
  text-decoration: none !important;
}

.portrait h1 {
  font-family: "Inter";
  text-align: right;
  color: #e50044;
  font-size: 28px;
  font-weight: 600;
}

.public-holiday {
  display: flex;
  /* justify-content: center; */
  list-style: none;
  margin-top: 2%;
  margin-bottom: 2%;
}
.public-holiday li {
  font-family: "Inter";
  font-weight: 500;
  font-size: 18px;
  padding-right: 50px;
}

.public-holiday-portrait li {
  font-family: "Poppins";
  font-weight: 500;
  font-size: 12px;
  list-style: none;
  /* padding-right: 50px; */
}

.public-holiday h6 {
  padding-right: 50px;
}

.checkbox-container label {
  margin-left: 10px;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  margin-bottom: 10px;
}

.portrait-event {
  display: flex;
  justify-content: space-between !important;
  padding-top: 10px;
}

.your-events {
  margin-top: 15px;
  text-align: right;
}
.your-events h6 {
  padding-right: 80px;
}

.your-events li {
  color: #bb95c2;
  font-size: 12px !important;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}

.landscape h1 {
  font-family: "Inter";
  font-size: 22px;
  color: #e50044;
  font-weight: 700;
}

.landscape-img {
  /* padding: 10px;
  background: #d9d9d9; */
  display: flex;
  justify-content: center;
  width: 100%;
  height: 50vh;
  position: relative;
  z-index: 1;
}

.landscape img {
  /* width: 100%; */
  object-fit: contain;
  width: 100%;
  height: 100%;
}
.portrait-img {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 80vh;
  position: relative;
  z-index: 1;
}

.portrait-img img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.landscape li {
  font-size: 12px;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  text-align: right;
  list-style: none;
  margin-right: 10px;
}

.landscape span {
  margin-right: 10px;
}
.portrait li {
  font-size: 12px;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  text-align: right;
  list-style: none;
  margin-right: 10px;
}

.portrait span {
  margin-right: 10px;
}

.land {
  display: flex;
  justify-content: space-between;
}

.land li {
  list-style: none;
  font-size: 12px;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  text-align: right;
}

.public-holiday-portrait {
  margin-top: 10px;
  display: inline-flex;
  flex-wrap: wrap;
  width: 100%;
}

.holiday-row {
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: end;
}

.holiday-item {
  display: inline-flex;
  white-space: nowrap;
  /* flex-wrap: wrap; */
}
.holiday-row-portrait {
  display: inline-flex;
  flex-wrap: wrap;
}

.holiday-item-portrait {
  display: inline-flex;
  white-space: nowrap;
  /* flex-wrap: wrap; */
}

.holiday-item li {
  margin-right: 10px;
  font-family: "Poppins", serif;
  font-weight: 600;
  font-size: 12px;
}
.holiday-item-portrait li {
  margin-right: 10px;
  font-family: "Poppins", serif;
  font-weight: 600;
  font-size: 12px;
}

.public-holiday-land {
  list-style-type: none;
  /* display: flex; */
  display: inline-flex;
  justify-content: end;
  flex-wrap: wrap;
  width: 100%;
}

.public-holiday-land li {
  margin-right: 10px;
  font-weight: 500;
  font-family: "Poppins", serif;
  font-size: 12px;
  text-align: right;
}
/* body::after {
  content: '';
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: url("../public/imgs-calendar/logo1.png");
  opacity: 0.3;
  pointer-events: none;
} */

.track-row {
  margin-top: 4%;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0px 4px 25px 0px #00000040;
  padding: 4%;
  margin-bottom: 4%;
}

.track-col {
  padding: 2%;
}

.track-col h5 {
  font-size: 20px;
  color: #000;
  font-family: "Poppins", sans-serif;
}

.track-col h6 {
  font-size: 18px;
  color: #545454;
  font-family: "Poppins";
}

.track-col p {
  font-size: 18px;
  color: #000;
  font-family: "Inter", sans-serif;
}

.track-row-2 {
  border-bottom: 1px solid #545454;
  margin-bottom: 3%;
  padding-left: 2% !important;
}

.track-detail {
  padding: 2%;
}

.track-detail p:first-child {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  color: #545454;
  margin-bottom: 0;
}
.track-detail p:last-child {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  color: #000;
}

.order-tracker {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  position: relative;
  margin-bottom: 3%;
}

.step {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  flex-grow: 1;
  text-align: center;
}

.circle {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: gray;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: bold;
  z-index: 1;
}

.circle.completed {
  background-color: #38a3a5;
}

.label {
  margin-top: 10px;
  font-size: 16px;
  font-family: "Poppins";
}

.label.completed {
  color: #38a3a5;
}

/* .line {
  position: absolute;
  top: 15%;
  transform: translateY(-50%);
  height: 5px;
  background-color: gray;
  z-index: 0;
}

.step:not(:last-child) .line {
  width: 100%;
}

.line.completed {
  background-color: blue;
}

.step + .step .line {
  left: -50%;
} */

.line-container {
  position: absolute;
  top: 22%;
  left: 55%;
  width: 100%;
  height: 3px;
  z-index: 0;
  background-color: gray;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.line {
  flex-grow: 1;
  height: 100%;
  background-color: gray;
}

.line.completed {
  background-color: #38a3a5;
}

@media (max-width: 900px) {
  .img-login {
    position: static;
  }
  .main-button {
    display: flex;
    justify-content: center;
  }
}

.portrait img {
  width: 100%;
}

/*------------------- Admin Dashboard CSS ------------*/

/*----------------- SIDEBAR CSS------------------------ */
.sidebar {
  position: fixed;
  top: 0%;
  left: 0;
  height: 100%;
  width: 260px !important;
  background-color: #fff;
  color: #000;
  transition: all 0.5s ease;
  z-index: 100;
  border-radius: 0px 10px 10px 0px;
}

.sidebar.close {
  width: 78px !important;
}

/* --------- Logo ------------ */

.logo-box {
  height: 60px;
  width: 100%;
  display: flex;
  align-items: center;
  transition: all 0.5s ease;
}

.logo-box img {
  margin-top: 5%;

  margin-left: 5%;
}
.sidebar.close .logo-box i {
  transform: rotate(180deg);
}

/* ---------- Sidebar List ---------- */
.sidebar-list {
  height: 100%;
  padding: 30px 0 150px 0;
  overflow: auto !important;
}

.sidebar-list::-webkit-scrollbar {
  display: none;
}

.sidebar-list li {
  transition: all 0.5s ease;
}

.sidebar-list li .title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all 0.5s ease;
  cursor: pointer;
}
.title i {
  color: #0d6efd !important;
}

.sidebar-list li.active .title {
  background-color: #a952a3;
  color: white;
}

.sidebar-list li.active .bi-chevron-down {
  transition: all 0.5s ease;
  transform: rotate(180deg);
}

.sidebar-list li .title .link {
  display: flex;
  align-items: center;
  text-decoration: none;
}

.sidebar-list li .title i {
  height: 50px;
  min-width: 78px;
  text-align: center;
  line-height: 50px;
  color: #292d32;
  font-size: 20px;
  margin-left: -20px;
}
.sidebar-list li .title svg {
  margin-right: 25px;
  height: 50px;
  width: 23px;
  fill: #292d32;
  margin-left: 10px;
}

.sidebar-list li .title .name {
  font-size: 14px;
  font-weight: 400;
  color: #0c0c0d;
}

.activeTab .name {
  font-size: 14px;
  font-weight: 600 !important;
  color: #4a92fe !important;
}

.css-1u0lry5-MuiChartsLegend-root {
  display: none;
}

/* ---------------- Submenu ------------- */
.sidebar-list li .submenu {
  display: none;

  width: 0;
  height: 0;
  opacity: 0;
  transition: all 0.5s ease;
}

.sidebar-list li.active .submenu {
  width: unset;
  height: unset;
  opacity: 1;
  display: flex;
  flex-direction: column;
  padding: 6px 6px 14px 80px;
  background-color: #eeeeee;
}

.sidebar-list li.dropdown.active .submenu {
  width: unset;
  height: unset;
  opacity: 1;
  display: flex;
  flex-direction: column;
  padding: 6px 6px 14px 80px;
  background-color: #eeeeee;
}

.submenu .link {
  color: #000;
  font-size: 15px;
  padding: 5px 0;
  transition: all 0.5s ease;
  text-decoration: none;
}

.submenu-title {
  display: none;
}

/* ---------------- Submenu Close ------------- */
.sidebar.close .logo-name,
.sidebar.close .title .name,
.sidebar.close .title .bi-chevron-down,
.sidebar.close .side-profile .side-name {
  display: none;
}

.sidebar.close .sidebar-list {
  overflow: visible;
}

.sidebar.close .sidebar-list li {
  position: relative;
}

.sidebar.close .sidebar-list li .submenu {
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 100%;
  top: -10px;
  margin-top: 0;
  padding: 10px 20px;
  border-radius: 0 6px 6px 0;
  height: max-content;
  width: max-content;
  opacity: 0;
  transition: all 0.5s ease;
  pointer-events: none;
  color: #000;
}

.sidebar.close .sidebar-list li:hover .submenu {
  opacity: 1;
  top: 0;
  pointer-events: initial;
  background-color: #1b59f8;
}

.sidebar.close .submenu-title {
  display: block;
  font-style: 18px;
}

.side-profile {
  position: absolute;
  bottom: 0;
  margin-left: 10px;
  border-top: 0.5px solid #e5e5e5;
  padding-top: 5%;
  padding-bottom: 5%;
}

.side-name {
  margin-left: 7%;
}
.side-name h3 {
  font-family: "Inter", sans-serif;
  font-size: 12.93px;
  font-weight: 600;
  text-align: left;
  color: #000;
}
.side-name h5 {
  font-family: "Inter", sans-serif;
  font-size: 11.08px;
  font-weight: 400;
  text-align: left;
  color: #00000080;
}

.home {
  position: relative;
  padding: 2%;
  padding-top: 2%;
  left: 235px;
  width: calc(100% - 210px);
  min-height: 100vh;
  transition: all 0.5s ease;
  background: #f5f5f5;
}

.sidebar.close ~ .home {
  left: 78px;
  width: calc(100% - 78px);
}

.home .toggle-sidebar {
  height: 60px;
  display: flex;
  align-items: center;
  width: fit-content;
  cursor: pointer;
}

.home .toggle-sidebar i {
  font-size: 25px;
  margin-left: 15px;
}

.home .toggle-sidebar .text {
  font-size: 25px;
  font-weight: 600;
}

.dashboard {
  padding-top: 0%;
  padding-bottom: 5%;
}

.dashboard-header h1 {
  font-family: "Poppins", sans-serif;
  font-size: 22.17px;
  font-weight: 600;
  color: #000;
}
.dashboard-header {
  padding-bottom: 1%;
  border-bottom: 1px solid #0000001a;
}

.button-right {
  display: flex;
  align-items: center;
  justify-content: end;
  margin-right: 4%;
  padding: 5px;
}

.dash-row {
  padding-top: 3%;
  padding-bottom: 3%;
}

.dash-card {
  border: 1px solid #e2e8f0;
  box-shadow: 0px 1px 2px 0px #0000000d;
  border-radius: 16px;
  background: #fff;
  padding-left: 5%;
  padding-right: 5%;
  padding-top: 3%;
}
.dash-1 {
  display: flex;
  justify-content: space-between;
}

.dash-1 h3 {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #0f172a;
}

.dash-1 span {
  color: #0d6efd;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 700;
  padding: 2px 5px;
  background-color: #ddebff;
  border-radius: 4px;
}

.dash-2 {
  display: flex;
  justify-content: space-between;
  padding-top: 6%;
}

.dash-2 h2 {
  font-family: "Poppins", sans-serif;
  font-size: 24px;
  font-weight: 600;
  color: #000;
}

.dash-2 h5 {
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-weight: 400;
  color: #000;
}

.dash-box {
  box-shadow: 0px 1px 3px 0px #00000014;
  background: #fff;
  border-radius: 12px;
  padding: 5%;
}

.dash-box h2 {
  font-family: "Inter", sans-serif;
  font-size: 24px;
  font-weight: 600;
  color: #202020;
}

.dash-box h5 {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #898989;
}
.pie-label {
  display: flex;
  justify-content: space-evenly;
  margin-top: 4%;
  margin-bottom: 2%;
}

.pie-label p {
  font-family: "Inter", sans-serif;
  font-size: 13px;
  font-weight: 400;
  color: #202020;
}
.pie-label span {
  background-color: #775da6;
  color: #775da6;
  border-radius: 10px;
  height: 2px;
  padding: 4px;
  margin-top: 6%;
  margin-right: 4px;
}
.span2 {
  background: #ffb1b7 !important;
}
.span3 {
  background: #70b6c1 !important;
}
.order1 {
  padding-bottom: 2%;
  border-bottom: 1px solid #eaeaea;
}

.order1 h3 {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 700;
  color: #0f172a;
}
.order1 h6 {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #0f172a;
}
.order1 span {
  padding: 5px 8px;
  border: 1px solid #94a3b8;
  border-radius: 8px;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #000;
}
.order2 {
  display: flex;
  justify-content: space-between;
  padding-top: 5%;
  border-bottom: 1px solid #eaeaea;
}

.order-img {
  margin-right: 3%;
}

.order-img img {
  padding: 5px;
  border: 1.81px solid #e2e8f0;
  border-radius: 8px;
}
.order2 h5 {
  font-family: "Poppins", sans-serif;
  font-size: 11px;
  font-weight: 500;
  line-height: 16.5px;
  text-align: left;
  color: #0f172a;
}

.order2 p {
  font-family: "Poppins", sans-serif;
  font-size: 11px;
  font-weight: 400;
  color: #000;
}

.maintable-column {
  background-color: #fff;
  border: 1px solid #55565a1f;
  border-radius: 8px;
  padding: 20px;
}
.foot-tablerow h5 {
  font-size: 25px;
  margin: 15px 25px;
  color: #545454;
}
.table select {
  width: 100px;
  height: 30px;
}
.tr1 {
  background: #fcfcfc !important;
}
/* .dt-search {
  display: none;
} */
th {
  background-color: #fcfcfc !important;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 500;

  color: #121212;
  padding: 12px !important;
  text-align: left !important;
}
td {
  background-color: #fff !important;
  font-weight: 400;
  font-size: 12px;
  font-family: "Inter", sans-serif;
  color: #7d8398;
  padding: 10px 13px !important;
  text-align: left !important;
}
.table input[type="checkbox"] {
  border: 1px solid #7d83984d;
  border-radius: 4px;
  box-shadow: 0px 2px 3px 0px #12121208;
}

td i {
  color: #0d6efd;
  padding: 5px;
  background: #d8e8ff;
  border-radius: 5px;
  cursor: pointer;
}

.table-banner {
  margin-left: 17% !important;
  /* padding: 5%; */
}
.dt-column-order {
  display: none !important;
}

.search-group {
  width: 30% !important;
  margin-bottom: 1%;
  margin-left: -1%;
  border: 1px solid #a8a8a870;
  background: transparent;
  border-radius: 10px;
  padding: 0.5%;
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  font-weight: 400;
}
.search-prepend span {
  border: 0;
  background: transparent;
}
.search-group input {
  background: transparent;
}
.search-group input:focus {
  background-color: transparent;
}

.dash h3 {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #0f172a;
}

.dash h2 {
  font-family: "Poppins", sans-serif;
  font-size: 24px;
  font-weight: 600;
  color: #000;
}

.pending span {
  display: flex;
  color: #d6a243 !important;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 500;
  background: #d6a2431f !important;
  padding: 4px 6px !important;
  border-radius: 40px;
  justify-content: center;
  /* margin-top: 5%; */
  border: 0;
}
.pending i {
  color: #d6a243;
  background: transparent;
}
.success span {
  display: flex;
  color: #91c561 !important;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 500;
  background: #91c5611f !important;
  padding: 2px 6px !important;
  border-radius: 40px;
  justify-content: center;
  /* margin-top: 5%; */
  border: 0;
}
.success i {
  color: #91c561;
  background: transparent;
}

.member-card {
  padding: 5%;
  border: 1px solid #e3e3e3;
  box-shadow: 0px 2px 18px 0px #0000001a;
  background: #fff;
  margin-top: 5%;
}

.member-card h1 {
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  font-weight: 500;
  color: #000;
}

.member-card p {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #747474;
}

.member-card h4 {
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  font-weight: 500;
  color: #000;
}
.member-card h5 {
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  font-weight: 500;
  color: #0d6efd;
}
.member-row {
  padding: 0 !important;
  margin-top: 4% !important;
}

.settings {
  border: 1px solid #e3e3e3;
  box-shadow: 0px 2px 18px 0px #0000001a;
  background: #fff;
  padding: 2%;
  margin-right: 3%;
  margin-top: 3%;
}
.settings h1 {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #24292f;
  margin-bottom: 2%;
}

.settings span {
  font-family: "Noto Sans", sans-serif;
  font-size: 12px;
  font-weight: 400;
  color: #24292f;
  padding: 5px;
  border: 1px solid #cccccc;
  margin-left: 2%;
}

.settings-row {
  padding-top: 3%;
  padding-bottom: 2%;
  border-bottom: 1px solid #0000001a;
}

.settings-item label,
.pass-item label {
  display: block;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #24292f;
}

.settings-item input {
  border: 1px solid #d0d7de;
  box-shadow: 0px 1px 0px 0px #d0d7de33 inset;
  background: #fafafa;
  padding: 2%;
  width: 100%;
  margin-top: 2%;
  margin-bottom: 2%;
  border-radius: 6px;
}

.settings-item input:focus {
  background: #d1d1d1;
  border: 1px solid #d0d7de;
  box-shadow: 0px 1px 0px 0px #d0d7de33 inset;
  outline: 0;
}

.pass-row {
  padding-top: 3%;
  padding-bottom: 3%;
}
.pass-row h1 {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: #000;
}
.pass-group {
  border: 1px solid #d0d7de;
  box-shadow: 0px 1px 0px 0px #d0d7de33 inset;
  background: #fafafa;
  width: 100%;
  margin-top: 2%;
  margin-bottom: 2%;
  border-radius: 6px;
}

.pass-prepend span {
  background: transparent;
  border: 0;
  margin: 7px;
  font-size: 15px;
  cursor: pointer;
}

.pass-group input {
  background: transparent;
  border: 0;
}
.pass-group input:focus {
  background: #d1d1d1;
  border: 1px solid #d0d7de;
  box-shadow: 0px 1px 0px 0px #d0d7de33 inset;
  outline: 0;
  transition: all 0.3s ease-in-out;
}

.app-input .form-control {
  background-color: transparent !important;
  border: none !important;
  padding: 0 !important;
}

.app-input .form-control.is-invalid,
.is-invalid {
  border: 1px solid #fa011a !important;
  border-radius: 4px !important;
}
.is-invalid-input {
  border-right: 0 !important;
  border-radius: 4px 0px 0px 4px !important;
}
.is-invalid-span {
  border-left: 0 !important;
  border-radius: 0px 4px 4px 0px !important;
  border: 1px solid #fa011a !important;
}

.app-input .form-control {
  display: flex;
}
.app-input input {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.filter-flex {
  display: flex;
  align-items: center;
  gap: 10px;
}

/*** Admin CSS ***/
.ck.ck-editor__main > .ck-editor__editable:not(.ck-focused) {
  height: 200px;
}
.ck-rounded-corners .ck.ck-editor__main > .ck-editor__editable,
.ck.ck-editor__main > .ck-editor__editable.ck-rounded-corners {
  height: 200px !important;
}
.add-new-box-btn button {
  background-color: #0e60a8;
  color: #fff;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  padding: 5px 15px;
  border-radius: 8px;
}
.plan-column label {
  color: #1d1b1b;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 5px;
}

.submit-planbtn {
  display: flex;
  justify-content: center;
}
.submit-planbtn button {
  background: #e98137;
  padding: 10px 20px;
  width: 300px;
  color: #fff;
  border-radius: 10px;
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  font-weight: 500;
}

/* End Admin CSS */

.profile-item input {
  border: 1px solid black;
}

/* Toggle Switch */
.switch {
  position: relative;
  display: inline-block;
  width: 34px;
  height: 20px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #d74545;
  transition: 0.4s;
  border-radius: 20px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 14px;
  width: 14px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #43e05d;
}

input:checked + .slider:before {
  transform: translateX(14px);
}
/* end */

.preview {
  width: 100%;
  padding: 10px 20px;
  background-color: #ffffff;
}

.landscape {
  position: relative;
  z-index: 2;
  width: 566.4px;
  height: 422.4px;
}

.portrait {
  position: relative;
  z-index: 2;
  /* width: 566.4px;
  height: 422.4px;
  border: 1px solid black; */
}

/* .watermark {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
}

.watermark img {
  transform: rotate(-15deg);
  width: 50%;
  height: 50%;
  opacity: 0.8 !important;
} */

img {
  max-width: 100%;
  height: 72%;
}

button {
  margin: 10px;
}

.order-span .red-text {
  margin-left: 10px;
  padding: 2px 8px;
  background: #f8021ee9;
  border-radius: 20px;
  font-family: "Inter";
  font-size: 15px;
  font-weight: 400;
}

.line-remove {
  text-decoration: none !important;
  font-weight: 700;
}
.line-removes {
  text-decoration: none !important;
  color: #f0f0f0;
}
.table-btn {
  border: none !important;
}

.hand-icon {
  cursor: pointer;
}

.portrait-calendar {
  height: 50vh;
}

.landscape-calendar {
  height: 30vh;
  width: 80% !important;
  object-fit: contain;
}

.calendar-table {
  width: 100%;
  height: 100%;
  object-fit: contain !important;
}

.calendar-head th {
  color: #1a1818;
  background-color: #afcb06 !important;
  text-transform: uppercase;
  font-weight: bold;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  padding: 5px 14px !important;
  text-align: center !important;
}

.calendar-head th:nth-child(7n + 1) {
  color: #e3000e;
}
.calendar-head th:nth-child(7n + 7) {
  color: #ef7c00;
}
.calendar-head-portrait th {
  color: #1a1818;
  background-color: #afcb06 !important;
  text-transform: uppercase;
  font-weight: bold;
  font-family: "Inter", sans-serif;
  font-size: 18px;
  padding: 5px 4px !important;
  text-align: center !important;
}

.calendar-head-portrait th:first-child {
  color: #e3000e;
}
.calendar-head-portrait th:last-child {
  color: #ef7c00;
}

.inactive-day {
  color: #b9b9b9;
  font-weight: 600;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  padding: 0 14px !important;
  text-align: center !important;
  border: 1px solid #ebebeb;
}

.calendar-day {
  color: #1a1818;
  font-weight: 600;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  padding: 0 14px !important;
  text-align: center !important;
  border: 1px solid #ebebeb;
}
.inactive-day-portrait {
  color: #b9b9b9;
  font-weight: 600;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  padding: 0 4px !important;
  text-align: center !important;
  border: 1px solid #ebebeb;
}

.calendar-day-portrait {
  color: #1a1818;
  font-weight: 600;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  padding: 0 4px !important;
  text-align: center !important;
  border: 1px solid #ebebeb;
}

.calendar-day:nth-child(7n + 7),
.calendar-day-portrait:nth-child(7n + 7) {
  color: #ef7c00 !important;
}

.calendar-day:nth-child(7n + 1),
.calendar-day-portrait:nth-child(7n + 1) {
  color: #e3000e !important;
}

/* .error{
  border: 2px solid rgb(108, 2, 2);
} */
.error .upload {
  background-color: rgba(202, 4, 4, 0.321);
}

/*** New Preview pdf CSS ***/

.custom-container {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

.print-pdf {
  display: flex;
  justify-content: center;
}

.horizontal-container {
  width: 5.9in; /* Match PDF width */
  height: 4.4in; /* Match PDF height */
  display: flex;
  gap: 10px;
  /* padding: 0 5px; */
  padding-top: 0.354in;
  padding-bottom: 5px;
  flex-direction: column;
}

.horizontal-container-coupon {
  width: 9in; /* Match PDF width */
  height: 5in; /* Match PDF height */
  display: flex;
  gap: 5px;
  /* padding: 0 5px; */
  padding-top: 0.354in;
  padding-bottom: 5px;
  flex-direction: column; /* Stack items vertically */
}

.horizontal-container-print {
  width: 9in; /* Match PDF width */
  height: 6in; /* Match PDF height */
  display: flex;
  gap: 5px;
  /* padding: 0 5px; */
  padding-top: 0.354in;
  padding-bottom: 5px;
  flex-direction: column; /* Stack items vertically */
}

.img-box {
  width: 100%; /* img-box takes up the full width */
  height: 2.767in; /* Adjust height as needed */
  display: flex;
  align-items: center;
  justify-content: center;
}

.img-box img {
  /* object-fit: cover;
    width: 100%; */
  max-width: 5.9in;
  height: 2.767in;
  /* border-radius: 10px; */
}

.img-box-coupon {
  width: 100%; /* img-box takes up the full width */
  height: 4in; /* Adjust height as needed */
  display: flex;
  align-items: center;
  justify-content: center;
}

.img-box-coupon img {
  max-width: 9in;
  height: 3in;
}

.calendar-box {
  width: 100%; /* calendar-box takes up the full width */
  display: flex;
  padding-left: 5px;
  padding-right: 5px;
  /* height: 1.377in; */
}

.calendar-box-coupon {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 20px;
}

.horizontal-calendar {
  flex: 5;
  align-items: center;
  justify-content: center;
  padding-right: 5px;
}

.horizontal-calendar-coupon {
  padding-right: 10px;
}

.horizontal-calendar-coupon h1 {
  font-family: "Copperplate Gothic Bold", sans-serif;
  font-size: 16px;
  color: rgb(248, 54, 33);
  margin-bottom: 0;
}

.horizontal-calendar h1 {
  font-family: "Copperplate Gothic Bold", sans-serif;
  font-size: 13px;
  color: rgb(248, 54, 33);
  margin-bottom: 0;
}

.horizontal-calendar table {
  font-family: "Bahnschrift";
}

.horizontal-event {
  flex: 4;
  align-items: center;
  justify-content: center;
}
.horizontal-event-coupon {
  display: flex;
  flex-direction: column;
  /* align-items: end;
  justify-content: end; */
}

.horizontal-calendar img,
.horizontal-event img {
  width: 100%; /* Images take full width */
  height: auto; /* Maintain aspect ratio */
}

.calender-logo {
  width: 1.34in;
  height: 0.9in;
  object-fit: contain;
}

.calender-logo-print {
  width: 1.34in;
  height: 0.8in;
  object-fit: contain;
}

.calender-logo-vertical {
  width: 1.34in;
  height: 0.75in;
  object-fit: contain;
}

.horizontal-logo-box {
  display: flex;
  padding-top: 15px;
  margin: 5px 0px;
}

.vertical-container {
  width: 5.9in;
  height: 4.4in;
  display: flex;
  gap: 10px;
  padding-top: 0.354in;
  /* padding-bottom: 5px;
    padding-left: 5px; */
  /* padding-right: 5px; */
  /* border: 1px solid #c92020; */
}

.vertical-container-coupon {
  width: 9in; /* Match PDF width */
  height: 5in;
  display: flex;
  gap: 10px;
  padding-top: 0.354in;
  /* padding-bottom: 5px;
    padding-left: 5px; */
  /* padding-right: 5px; */
}

.vertical-container-print {
  width: 9in; /* Match PDF width */
  height: 6in;
  display: flex;
  gap: 10px;
  padding-top: 0.354in;
  /* padding-bottom: 5px;
    padding-left: 5px; */
  /* padding-right: 5px; */
}

.vertical-img-box {
  width: 3.687in;
  display: flex;
  align-items: center;
  justify-content: center;
}

.vertical-img-box-coupon {
  width: 4.6in;
  display: flex;
  align-items: center;
  justify-content: center;
}

.vertical-img-box-coupon img {
  width: 100%;
  height: 4.6in;
}

/* .vertical-img-box img {
    max-width: 100%;
    max-height: 4.2in;
    object-fit: cover;
    border-radius: 10px;
    border: 8px solid #d9d9d9;
  } */

.vertical-img-box img {
  width: 100%;
  height: 4.02in;
  /* object-fit: cover; */
  /* border-radius: 10px; */
  /* border: 8px solid #d9d9d9; */
}

.vertical-calendar-box {
  width: 50%; /* vertical-calendar-box takes up the other half of the container width */
  display: flex;
  /* align-items: center; */
  flex-direction: column; /* Stack calendar and event vertically */
  padding-top: 10px;
  justify-content: start; /* Center content vertically */
  padding-right: 5px;
}

.vertical-calendar-box-coupon {
  width: 50%; /* vertical-calendar-box takes up the other half of the container width */
  display: flex;
  /* align-items: center; */
  flex-direction: column; /* Stack calendar and event vertically */
  padding-top: 10px;
  justify-content: start; /* Center content vertically */
  padding-right: 5px;
  position: relative;
}

.vertical-calendar-box-coupon h1 {
  font-family: "Copperplate Gothic Bold", sans-serif;
  font-size: 16px;
  color: rgb(248, 54, 33);
  margin-left: 5px;
}

.vertical-calendar-box h1 {
  font-family: "Copperplate Gothic Bold", sans-serif;
  font-size: 13px;
  color: rgb(248, 54, 33);
  margin-left: 5px;
}

.vertical-calendar {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 5px;
}

.vertical-calendar-coupon {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 5px;
  padding-right: 5px;
}

.vertical-event {
  padding-top: 20px;
  display: block;
  align-items: center;
  justify-content: center;
}

.vertical-calendar img,
.vertical-event img {
  max-width: 100%;
  height: auto;
}

.vertical-logo-box {
  width: 100%;
  display: flex;
  align-content: end;
  justify-content: end;
  margin-top: 5px;
  margin-bottom: 5px;
}

.vertical-logo-box-print {
  width: 100%;
  display: flex;
  align-content: end;
  justify-content: end;
  padding: 5px 10px;
  position: absolute;
  bottom: 15px;
}

.layout-imgbox {
  width: 5.9in; /* PDF width */
  height: 4.4in; /* PDF height */
  display: flex;
  flex-direction: column; /* Stack items vertically */
  /* border: 1px solid #ddd;   */
  padding-top: 0.28in;
}

.pdf-inner-pages h1 {
  font-family: "Copperplate Gothic Bold", sans-serif;
  font-size: 13px;
  color: rgb(248, 54, 33);
}
.pdf-event {
  display: flex;
  padding-bottom: 5px;
}
.pdf-event p {
  margin-bottom: 0;
  font-family: "TT1020M", sans-serif;
  font-size: 12px;
}

.pdf-event li {
  list-style: none;
  font-size: 10px;
  font-weight: 500;
  font-family: "Poppins";
  font-family: "TT1020M", sans-serif;
  margin-bottom: 5px;
}

.pdf-event-vertical {
  display: flex;
  align-items: center;
  gap: 6px;
  list-style: none;
  font-size: 13px;
  font-weight: 500;
  font-family: "Poppins";
  font-family: "TT1020M", sans-serif;
  padding-left: 5px;
  padding-bottom: 8px;
}

.pdf-event-verticalPrint {
  display: flex;
  align-items: center;
  gap: 6px;
  list-style: none;
  font-size: 13px;
  font-weight: 500;
  font-family: "Poppins";
  font-family: "TT1020M", sans-serif;
  padding-left: 5px;
}

.pdf-holiday-vertical {
  list-style: none;
  font-size: 13px !important;
  font-family: "TT1020M", sans-serif;
  font-weight: 500;
  padding-left: 5px;
}

.pdf-event-coupon div {
  display: flex;
  align-items: end;
  justify-content: end;
  gap: 6px;
  list-style: none;
  font-size: 13px;
  font-weight: 500;
  font-family: "Poppins";
  font-family: "TT1020M", sans-serif;
}

.pdf-holiday-coupon div {
  /* display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 3px; */
  display: flex;
  justify-content: end;

  list-style: none;
  font-size: 13px !important;
  font-family: "TT1020M", sans-serif;
  font-weight: 500;
  /* border: 2px solid #c92020; */
}

.pdf-holiday-coupon {
  padding-top: 10px;
}

.pdf-holiday li {
  list-style: none;
  font-size: 12px !important;
  font-family: "TT1020M", sans-serif;
  font-weight: 500;
  letter-spacing: 0.7px;
  margin: -2px 0;
}
.pdf-holiday {
  margin-top: 5px;
  font-family: "TT1020M", sans-serif;
  font-size: 12px;
}

.pdf-holiday span {
  margin: 0 3px;
}
.custom-container {
  padding: 20px 40px;
  display: flex;
  justify-content: space-between;
}
.back-link {
  color: #000;
  font-size: 18px !important;
  font-weight: 500;
  font-family: "Poppins";
  text-decoration: none;
}
.custom-container button {
  background-color: #018bb5;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 50px;
  font-size: 16px !important;
  font-weight: 500;
  font-family: "Poppins";
}

/* Styles for the container and button */
.custom-container {
  padding: 20px 40px;
  display: flex;
  justify-content: space-between;
}

.back-link {
  color: #000;
  font-size: 18px !important;
  font-weight: 500;
  font-family: "Poppins";
}

.custom-container button {
  background-color: #018bb5;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 50px;
  font-size: 16px !important;
  font-weight: 500;
  font-family: "Poppins";
}

/* Styles for the small loader */
.small-loader {
  border: 3px solid rgba(255, 255, 255, 0.1);
  border-radius: 50%;
  border-top: 3px solid #ffffff;
  width: 24px;
  height: 24px;
  animation: spin 0.8s linear infinite;
  display: inline-block;
  vertical-align: middle;
  margin-left: 10px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.bottom-layout-box {
  display: flex;
  justify-content: center;
}

.layout-imgbox {
  width: 5.9in; /* Match PDF width */
  height: 4.4in; /* Match PDF height */
}

.layout-imgbox-coupon {
  width: 9in; /* Match PDF width */
  height: 6in; /* Match PDF height */
  margin: 5px;
}

.watermark {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 1;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: rotate(-60deg);
}

.watermark img {
  max-width: 80%;
  height: auto;
}

.upper-layout-box {
  display: flex;
  justify-content: center;
  align-items: center;
}

.no-border {
  border: none !important;
}

.upper-textbox {
  width: 5.9in;
  height: 4.4in;
  /* border: 1px solid lightgray; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.upper-textbox-coupon {
  width: 9in;
  height: 6in;
  /* border: 1px solid lightgray; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.user-detail-name h6 {
  font-family: "Advent Pro", sans-serif;
  font-size: 32px;
  font-weight: 500;
  text-align: center;
}

.user-detail-name span {
  color: #ff3868;
}

.company-basic-detail {
  /* position: absolute; */
  /* top: 43%;
  left: 40%; */
  text-align: center;
  margin-top: 100px;
}
.link-class {
  color: #0c08cd;
  font-family: "OstrichSans-Black", sans-serif;
  font-weight: 500;
  font-size: 28px;
  margin-bottom: 0px;
}
.press-class {
  color: #000;
  font-weight: 500;
  font-family: "Bahn";
  text-decoration: none;
  font-size: 18px;
}

.press-class-coupon {
  color: #000;
  font-weight: 500;
  font-family: "Bahn";
  text-decoration: none;
  font-size: 18px;
  opacity: 0.7;
}

.shipping-pdf-info h5 {
  font-family: "Carrois Gothic", sans-serif;
  font-weight: 600;
  font-size: 25px;
  color: #1d1c1c;
  text-decoration: underline;
}

.shipping-pdf-info h6 {
  font-family: "Carrois Gothic", sans-serif;
  font-weight: 600;
  font-size: 18px;
  color: #818181;
}
.address-num p {
  font-family: "Carrois Gothic", sans-serif;
  font-weight: 600;
  margin-bottom: 0;
  font-size: 19px;
  color: #818181;
}
.address-num {
  margin-top: 15px;
}

.shipping-pdf-info {
  /* position: absolute; */
  width: 350px;

  /* bottom: -15%;
  left: 39%; */
}

.shipping-pdf-coupon {
  /* position: absolute; */
  width: 8in;
  height: 3.8in;

  /* bottom: -15%;
  left: 39%; */
}
.shipping-pdf-coupon h5 {
  font-family: "Carrois Gothic", sans-serif;
  font-weight: 600;
  font-size: 28px;
  color: #1d1c1c;
  text-decoration: underline;
}

.shipping-pdf-coupon h6 {
  font-family: "Carrois Gothic", sans-serif;
  font-weight: 600;
  font-size: 20px;
  color: #818181;
}

.courier-box {
  padding-top: 20px;
  margin-top: 20px;
  padding-left: 25px;
}

.undelivered-box h6 {
  font-family: "Carrois Gothic", sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: #636060;
  margin-bottom: 0;
  text-align: right;
}
.undelivered-box p {
  font-family: "Carrois Gothic", sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #636060;
  margin-bottom: 0;
  text-align: right;
}

.undelivered-box {
  transform: translateX(20%);
  margin-top: 20px;
}
.undelivered-box p {
  width: 350px;
  /* margin-top: 10px; */
  font-family: "Carrois Gothic", sans-serif;
}
.undelivered-box-coupon h6 {
  font-family: "Carrois Gothic", sans-serif;
  font-weight: 400;
  font-size: 20px;
  color: #636060;
  margin-bottom: 0;
  text-align: right;
}

.undelivered-box-coupon p {
  /* margin-top: 10px; */
  font-family: "Carrois Gothic", sans-serif;
  width: 350px;
  font-weight: 400;
  font-size: 18px;
  color: #636060;
  margin-bottom: 10px;
  text-align: right;
}

.undelivered-box-coupon {
  transform: translateX(55%);
}

.outer-container-box {
  display: flex;
  justify-content: center;
}

.switch-width {
  margin-left: 5px;
}
.switch-width input {
  cursor: pointer;
}
.switch-width label {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 15px;
}

.month-event {
  display: block;
}
.pdf-event li {
  margin: -3px 4px;
}

.img-upload-loader {
  display: flex;
  justify-content: center;
  margin-bottom: 5%;
  margin-top: 3%;
}

.loader-img {
  width: 50px;
  padding: 8px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: #38a3a5;
  --_m: conic-gradient(#0000 10%, #000), linear-gradient(#000 0 0) content-box;
  -webkit-mask: var(--_m);
  mask: var(--_m);
  -webkit-mask-composite: source-out;
  mask-composite: subtract;
  animation: l3 1s infinite linear;
}
@keyframes l3 {
  to {
    transform: rotate(1turn);
  }
}

/* changes */
.img-box1 {
  width: 100%; /* img-box takes up the full width */
  height: 2.767in; /* Adjust height as needed */
  display: flex;
  align-items: center;
  justify-content: center;
}

.img-box1 img {
  /* object-fit: cover;
  width: 100%; */
  max-width: 5.9in;
  height: 2.767in;
  /* border-radius: 10px; */
  /* border: 8px solid #d9d9d9; */
}

.img-box1-coupon {
  width: 100%; /* img-box takes up the full width */
  height: 2.767in; /* Adjust height as needed */
  display: flex;
  align-items: center;
  justify-content: center;
}
.img-box1-coupon img {
  max-width: 9in;
  height: 5.767in;
}

.img-box2 {
  width: 100%; /* img-box takes up the full width */
  height: 2.767in; /* Adjust height as needed */
  display: flex;
  align-items: center;
  overflow: hidden;
  justify-content: center;
}

.img-box2 img {
  /* object-fit: cover;  
  width: 100%; */
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  /* border-radius: 10px; */
  /* border: 8px solid #d9d9d9; */
  object-fit: cover;
}

.img-box2-coupon {
  width: 100%;
  height: 5.767in;
  display: flex;
  align-items: center;
  overflow: hidden;
  justify-content: center;
}

.img-box2-coupon img {
  width: 9in;
  height: 5.767in;
}

.vertical-img-box1 {
  width: 50%; /* vertical-img-box takes up half of the container width */
  display: flex;
  align-items: center;
  justify-content: center;
}

.vertical-img-box1 img {
  max-width: 100%;
  height: 100%;
  object-fit: contain;
  /* border-radius: 10px; */
  /* border: 8px solid #d9d9d9; */
}

.vertical-img-box2 {
  width: 50%; /* vertical-img-box takes up half of the container width */
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.vertical-img-box2 img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* border-radius: 10px; */
  /* border: 8px solid #d9d9d9; */
}

.replace-img button {
  background-color: #018bb5;
  border-radius: 20px;
  padding: 8px 20px;
  font-size: 14px;
  color: #fff;
}

.edit-img button {
  background-color: #018bb5;
  border-radius: 20px;
  padding: 8px 15px;
  font-size: 14px;
  color: #fff;
}
.edit-img i {
  font-size: 12px;
}

.next-button {
  background-color: #018bb5 !important;
  color: #fff !important;
  padding: 8px 15px;
  border-radius: 20px;
}

.pre-button {
  background-color: #aaaaaa !important;
  color: #fff !important;
  padding: 8px 15px;
  border-radius: 20px;
}

.modal-content1 p {
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  color: #410101;
}

.modal-footer-btn {
  padding: 5px 20px;
  background: #018bb5 !important;
  border: 1px solid #018bb5 !important;
  border-radius: 6px;
  color: #fff;
  font-family: "Poppins";
  font-weight: 500;
  font-size: 16px;
}
.pending-modal label {
  font-family: "Poppins";
  font-weight: 500;
  font-size: 14px;
}
.pending-modal input:focus {
  box-shadow: none;
  outline: 0;
}
.pending-modal input {
  height: 50px;
  font-family: "Poppins";
  font-weight: 400;
  font-size: 14px;
  color: #141414;
}

/* Add css Calendar */
/* Example Popup Styles */
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent overlay */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* To ensure it stays above other content */
}

.popup-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  width: 400px; /* Customize the width */
  max-width: 90%; /* Responsive behavior */
  animation: fadeIn 0.3s ease-in-out; /* Add animation */
}

.popup-content h3 {
  margin-top: 0;
  font-family: "Arial", sans-serif;
  color: #333;
}

.popup-content .form-group {
  margin-bottom: 15px;
}

.popup-content input[type="text"],
.popup-content textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
}

.popup-content button {
  background-color: #018bb5 !important;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.2s;
}

.popup-content button:hover {
  background-color: #018bb5 !important;
}

/* Fade-in animation for the popup */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.iv {
  position: relative;
  top: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  font-size: 22px;
  cursor: pointer;
  color: red;
}

.bt-text {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.imp-td {
  cursor: pointer !important;
}

.important-td {
  cursor: pointer !important;
}

.cursive {
  font-family: "Beyond Infinity", cursive;
  font-style: italic;
  font-size: 28px;
  padding: 0;
  margin: 0;
}

.cursive-coupon {
  font-family: "Beyond Infinity", cursive;
  font-style: italic;
  font-size: 30px;
  padding: 0;
  margin: 0;
  font-weight: 800;
}

.logo-div-coupon {
  padding-bottom: 10px;
}

.font-strong {
  font-family: "Bahnschrift";
  font-weight: 600;
  font-size: 24px;
  font-style: normal;
  letter-spacing: -1px;
}

.logo-img {
  object-fit: cover;
  object-position: center;
}

.notepad-container {
  width: 5.9in;
  height: 4.5in;
  background-color: white;
  position: relative;
  /* box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1); Adds shadow to the notepad */
}

.notepad-container-coupon {
  width: 9in;
  height: 5in;
  background-color: white;
  position: relative;
}

/* Header for the notepad */
.header {
  padding: 10px;
  /* border-bottom: 1px solid #c0c0c0; */
  color: rgb(241, 36, 94);
  text-decoration: underline;
  font-weight: bold;
  font-size: 20px;
}

/* Body area */
.notepad-body {
  height: calc(100% - 11.4%);
  padding: 0;
  margin: 0;
  position: relative;
  background-color: white;
  background-image: radial-gradient(lightgray 6%, transparent 6%),
    /* Dots */ radial-gradient(lightgray 6%, transparent 6%);
  background-size: 30px 30px;
  background-position: 0 12.5, 0px 12.5px;
}

.notepad {
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-footer-delete-btn {
  padding: 5px 20px;
  background: #d63131 !important;
  border: 1px solid #d63131 !important;
  border-radius: 6px;
  color: #fff;
  font-family: "Poppins";
  font-weight: 500;
  font-size: 16px;
}

/* COUPON VIEW DOWNLOAD */
/* Container for the whole page */
/* .container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  font-family: Arial, sans-serif;
} */

/* Coupon Design Box */

.head {
  font-size: 52px;
  text-transform: uppercase;
  text-align: center;
  color: #000;
  font-family: "OstrichSans-Regular";
  font-weight: 500;
}

/* .free {
  color: red;
  font-size: 40px;
  text-align: center;
  font-family: "Breathing";
  margin-bottom: -20px;
  z-index: 50;
  position: relative;
} */

.calendar-image {
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.calendar-image img {
  width: 50%;
  height: 50%;
}

.bottom-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.logo-img {
  width: 1.34in;
  height: 1in;
  object-fit: contain;
}

.coupon-box {
  border: 2px solid #000;
  padding: 0px 20px;
}

.coupon {
  font-weight: 700;
  font-size: 28px;
  color: #000;
  text-transform: uppercase;
  margin-bottom: -3px;
}

.code {
  background-color: rgb(244, 6, 6);
  color: white;
  padding: 1px;
  font-weight: bold;
  width: 100%;
  text-align: center;
  font-size: 22px;
}

.link-visit {
  text-decoration: none;
  color: #000;
  font-family: "OstrichSans-Regular";
  font-size: 28px;
  text-align: end;
  display: flex;
  justify-content: end;
  align-items: end;
  font-weight: 500;
  margin-bottom: -10px;
  margin-top: -20px;
}

.right-section {
  background-color: black;
  color: #ffcc00;
  padding: 20px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  position: relative; /* Ensure relative positioning to allow pseudo-element positioning */
  z-index: 99;
}

.right-section h3 {
  font-size: 22px;
  text-transform: uppercase;
  border: 2px solid white;
  padding: 6px;
  margin-top: 10px;
}

.right-section ul {
  list-style: none;
}

.website {
  color: rgb(250, 234, 5);
  font-family: "OstrichSans-Regular";
  margin-top: -20px;
  font-size: 18px;
}

.link-at {
  text-decoration: none;
  color: rgb(250, 234, 5);
  font-size: 16px;
}

.right-section-circle {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 1px;
  z-index: -10;
  /* border: 2px solid white; */
}

.circle-container {
  width: 100%;
  height: 100%;
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /* border: 2px solid white; */
}

.circle-small {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: black;
}

.process-left {
  border-left: 2px solid white;
  margin-top: -10px;
  overflow: hidden;
}

.li-terms {
  color: white;
  font-size: 44px;
  font-family: "OstrichSans-Regular";
  font-weight: 500;
  letter-spacing: 2px;
  padding-top: 10px;
  position: relative;
}

.li-terms::before {
  content: "";
  position: absolute;
  left: -65px;
  top: 35%;
  transform: translateY(-50%);
  rotate: 90deg;
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-bottom: 40px solid;
}

.container-coupon {
  /* width: 100vw; */
  height: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.calendar-grid {
  display: grid;
  background: white;
  max-width: 60vw;
  grid-template-columns: 3fr 1fr;
  height: 100%;
  margin-bottom: 20px;
}

.left-section {
  padding: 42px;
}

.calendar-inches {
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  padding-top: 5px;
  color: #38a3a5;
  font-family: "Poppins";
}

.coupon-basic {
  border: 2px solid black;
  margin: 20px;
}


.custom-button {
  background-color: #38a3a5; 
  /* background-color: #001414;  */
  color: white;
  border: none;
  border-radius: 2px;
  cursor: pointer;
  text-decoration: none;
}
